import { Assignment, AssignmentLate, AssignmentTurnedIn, Build, CardTravel, Help } from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import React from 'react';
//TODO: why do we import icons from two different places material-ui/icons and material-ui/icons/{name} ?
export const ADMIN_ID = 1;

export const ROUTES = {
  home: "/",
  orders: "/orders",
  employees: "/employees",
  orderLog: "/order-log",
  materials: "/materials",
  createOrder: "/create-order",
};

export const GET_PARAMS = {
  STATUS: "status",
  CHANGED_BY: "changed_by",
};

export const SEND_FROM_TYPES = {
  EXPRESSION: "EXPRESSION",
  TRANSPADENT: "TRANSPADENT",
};

export const ORDER_LOG_FILTER_STATUSES = [
  { name: "BASIC_INFO", title: "Базовая информация" },
  { name: "ORDER_RECEIVED", title: "Заказ получен" },
  { name: "PROJECT_APPROVED", title: "Проект подтвержден" },
  { name: "PROJECT_FINISHED", title: "Проект выполнен" },
  { name: "ALIGNERS_READY", title: "Элайнеры изготовлены" },
  { name: "ORDER_SEND", title: "Заказ отправлен" },
  { name: "FINANCIAL_INFO", title: "Финансовый раздел" },
  { name: "ORDER_COMMENT", title: "Комментарии к заказу" },
];
//TODO: fix id and name swap bug
export const ALIGNER_SYSTEM_SELECT_OPTIONS = [{"id":"TRANSPADENT", "name":"TRANSPADENT"},
                                              {"id":"EXPRESSION", "name":"EXPRESSION"}
                                             ];


export const ALIGNER_SYSTEM_IMAGES = {"TRANSPADENT":require("./assets/img/transpadent-logo.png"),
                                      "EXPRESSION":require("./assets/img/expression-logo.png")
                                    };
                                      
export const FINANCE_STATUS = {
                               "FREE":"FREE",
                               "PAYING":"PAYING"
                              }
export const STATUSES = {
  BASIC_INFO: {
    title: "BASIC_INFO",
    name: "Базовая информация",
    permission: "can_edit_basic_info",
    icon:  null
  },
  ORDER_CREATED: {
    title: "ORDER_CREATED",
    name: "Заказ создан",
    icon:  (props={}) =><Help {...props}/>
  },
  ORDER_RECEIVED: {
    title: "ORDER_RECEIVED",
    name: "Заказ получен",
    permission: "can_edit_order_received",
    icon:  (props={}) =><Assignment {...props}/>
  },
  PROJECT_FINISHED: {
    title: "PROJECT_FINISHED",
    name: "Проект выполнен",
    permission: "can_edit_project_done",
    icon:  (props={}) =><AssignmentTurnedIn {...props}/>
  },
  PROJECT_APPROVED: {
    title: "PROJECT_APPROVED",
    name: "Проект подтвержден",
    permission: "can_edit_project_approved",
    icon:  (props={}) =><AssignmentLate {...props}/>
  },
  ALIGNERS_READY: {
    title: "ALIGNERS_READY",
    name: "Элайнеры изготовлены",
    permission: "can_edit_aligners_done",
    icon:  (props={}) =><Build {...props}/>
  },
  SEND_TO_PRINT:{
    title: "SEND_TO_PRINT",
    name: "Отправлено в печать",
    permission: "can_edit_send_print",
    icon:  (props={}) =><PrintIcon {...props}/>
  },
  ORDER_PRINTED: {
    title: "ORDER_PRINTED",
    name: "Напечатано",
    permission: "can_edit_printed",
    icon:  (props={}) =><AllInboxIcon {...props}/>
  },
  ORDER_SEND: {
    title: "ORDER_SEND",
    name: "Заказ отправлен",
    permission: "can_edit_order_send",
    icon:  (props={}) =><CardTravel {...props}/>
  },
  FINANCIAL_INFO: {
    title: "FINANCIAL_INFO",
    name: "Финансовая информация",
    permission: "can_edit_financial_info",
  },
  ACTION_LOG: {
    title: "ACTION_LOG",
    name: "Действия",
    permission: "can_view_action_log",
  },
  MATERIALS: {
    title: "MATERIALS",
    name: "Материалы",
    permission: "can_view_material_expense",
  },
  DOWNLOAD_CSV :{
    title: "DOWNLOAD_CSV",
    name: "Загрузка заказов в csv",
    permission: "can_download_orders_csv"
  }
};

export const PERMISSIONS_API_FIELDS = {
  [STATUSES.ORDER_RECEIVED.title]: ["received_date", "project_planned_date"],
  [STATUSES.PROJECT_FINISHED.title]: ["who_completed_order", "card_number", "project_finished_date"],
  [STATUSES.PROJECT_APPROVED.title]: ["approved_date", "number_of_aligners", "number_of_upper_aligners", "number_of_lower_aligners", "number_of_templates", "plate_type"],
  [STATUSES.ALIGNERS_READY.title]: ["who_made_aligners",  "number_of_plates"],
  [STATUSES.ORDER_SEND.title]: ["ready_date", "parcel_number"],
  [STATUSES.SEND_TO_PRINT.title]: ["planned_send_date", "process_stage", "have_retainer", "print_file"],
  [STATUSES.ORDER_PRINTED.title]: [''], //#TODO: what to do here ?
  [STATUSES.FINANCIAL_INFO.title]: [
    "prepayment_received",
    "prepayment",
    "prepayment_received_date",
    "treatment_continuation",
    "free_replacement",
    "order_paid",
    "order_sum",
    "order_paid_date",
    "finance_status"
  ],
};

export const ORDER_TYPES = {
  MAKE_ALIGNERS: {
    title: "MAKE_ALIGNERS",
    name: "Готовые элайнеры",
  },
  MODELS_3D: {
    title: "MODELS_3D",
    name: "3D модели",
  },
};

export const OrderTypeMapping = {
  "INITIAL": "Первичный",
  "SECONDARY": "Уточняющий",
  "THERAPY_CONTINUATION": "Продолжение лечения",
  "SPARE_ALIGNER": "Дополнительный элайнер",
  "RETAINER": "Ретейнер"
}