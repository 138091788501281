import { format } from "date-fns";

export function formatDate(date) {
  return new Date(date).toLocaleDateString("ru", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "UTC",
  });
}

export function formatDateNew(date) {
  return format(new Date(date), "dd.MM.yyyy");
}
