import { API } from "../../modules/Api.js";
export const SET_AUTH = "SET_AUTH";
export const START_LOGIN = "START_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_LOADING_PROFILE = "SET_LOADING_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

const startLogin = () => ({ type: START_LOGIN });

const loginError = (error) => ({
  type: LOGIN_ERROR,
  payload: error,
});

const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, payload: data.account });

const setLoadingProfile = () => ({ type: SET_LOADING_PROFILE });

const clearProfile = () => ({ type: CLEAR_PROFILE });

const getProfileSuccess = (data) => ({ type: GET_PROFILE_SUCCESS, payload: data });

const getProfileError = (error) => ({ type: GET_PROFILE_ERROR, payload: error });

export const login = (username, password, onSuccess, onError) => (dispatch) => {
  dispatch(startLogin());

  return API.post("auth/login", {
    username,
    password,
  })
    .then(({ data }) => {
      dispatch(loginSuccess(data.data));
      dispatch(getProfile());
      onSuccess();
    })
    .catch((error) => {
      dispatch(loginError(error));
      onError();
    });
};

export const getProfile = () => (dispatch) => {
  dispatch(setLoadingProfile());

  return API.get("auth/profile")
    .then(({ data }) => {
      dispatch(getProfileSuccess(data));
    })
    .catch((error) => {
      dispatch(getProfileError(error));
    });
};

// Logout
export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch(setAuth());
  dispatch(clearProfile());
};

export const setAuth = (force = false) => ({
  type: SET_AUTH,
  payload: !force ? !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER) : false,
});
