export default {
  "/": {
    ru: {
      title: "Вхід у панель співробітника компанії TranspaDent",
      desc: "Сторінка призначена лише для співробітників компанії TranspaDent",
      keys: "вхід",
      url: "/",
      img: require("./assets/log_in.jpg"),
      favicon: require("../assets/favicon-inner.png"),
    },
  },

  "/create-order": {
    ru: {
      title: "Електронне замовлення елайнерів TranspaDent",
      desc: "Електронна форма для створення замовлень елайнерів TranspaDent лікарями-стоматологами",
      keys: "замовлення",
      url: "/create-order",
      img: require("./assets/order_create.jpg"),
      favicon: require("../assets/favicon-create-order.png"),
    },
  },
};
