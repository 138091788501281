import { GLOBAL_LOADING } from "../actions/globalLoadingActions";

const initialState = {
  loading: false,
};

export default function globalLoadingReducer(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
