export const DIALOGS = {
  ACCEPT_ACTION: "ACCEPT_ACTION",
  MATERIALS_LIST: "MATERIALS_LIST",
  FINAL_CALCULATIONS: "FINAL_CALCULATIONS",
};

const initialState = {
  isShow: false,
  data: null,
  onSubmit: null,
  onClose: null,
};

export function createDialogsReducer() {
  const reducers = {};
  Object.values(DIALOGS).forEach((dialogName) => {
    reducers[dialogName] = (state = initialState, action) => {
      if (action.type !== dialogName) {
        return state;
      }
      return { ...state, ...action };
    };
  });
  return reducers;
}
