import { API } from "../../modules/Api";
import { setInfoPopup } from "./infoPopupActions";
import { formatDate } from "../../modules/formatDate";

/**
 * Get all materials
 * */
export const GET_MATERIALS_START = "GET_MATERIALS_START";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";
export const GET_MATERIALS_ERROR = "GET_MATERIALS_ERROR";

function getMaterialsStart() {
  return {
    type: GET_MATERIALS_START,
  };
}
function getMaterialsSuccess(materials) {
  return {
    type: GET_MATERIALS_SUCCESS,
    payload: materials,
  };
}
function getMaterialsError() {
  return {
    type: GET_MATERIALS_ERROR,
  };
}

export function getMaterials() {
  return async (dispatch) => {
    dispatch(getMaterialsStart());
    try {
      const { data: materials } = await API.get("/material");
      dispatch(getMaterialsSuccess(materials));
    } catch (error) {
      dispatch(getMaterialsError());
      dispatch(setInfoPopup("Ошибка"));
    }
  };
}

/**
 * Create/update all materials
 * */
export const SET_MATERIALS_START = "SET_MATERIALS_START";
export const SET_MATERIALS_SUCCESS = "SET_MATERIALS_SUCCESS";
export const SET_MATERIALS_ERROR = "SET_MATERIALS_ERROR";

function setMaterialsStart() {
  return {
    type: SET_MATERIALS_START,
  };
}
function setMaterialsSuccess(materials) {
  return {
    type: SET_MATERIALS_SUCCESS,
    payload: materials,
  };
}
function setMaterialsError() {
  return {
    type: SET_MATERIALS_ERROR,
  };
}

export function setMaterials(newMaterials, onSuccess) {
  return async (dispatch) => {
    dispatch(setMaterialsStart());
    try {
      const { data: updatedMaterials } = await API.post("/material/batch", { rows: newMaterials });
      dispatch(setMaterialsSuccess(updatedMaterials));
      dispatch(setInfoPopup("Сохранено"));
      onSuccess();
    } catch (error) {
      dispatch(setMaterialsError());
      dispatch(setInfoPopup("Ошибка"));
    }
  };
}

/**
 * Get final sum
 * */
export const GET_FINAL_SUM_START = "GET_FINAL_SUM_START";
export const GET_FINAL_SUM_SUCCESS = "GET_FINAL_SUM_SUCCESS";
export const GET_FINAL_SUM_ERROR = "GET_FINAL_SUM_ERROR";

function getFinalSumListStart() {
  return {
    type: GET_FINAL_SUM_START,
  };
}
function getFinalSumListSuccess(finalSumList) {
  return {
    type: GET_FINAL_SUM_SUCCESS,
    payload: finalSumList,
  };
}
function getFinalSumListError() {
  return {
    type: GET_FINAL_SUM_ERROR,
  };
}

export function getFinalSumList(queryDates, onSuccess) {
  return async (dispatch) => {
    dispatch(getFinalSumListStart());
    try {
      const { data: updatedMaterials } = await API.get("/material/expense/sum", {
        params: { date_after: formatDate(queryDates.startDate), date_before: formatDate(queryDates.endDate) },
      });
      dispatch(getFinalSumListSuccess(updatedMaterials));
      onSuccess();
    } catch (error) {
      dispatch(getFinalSumListError());
      dispatch(setInfoPopup("Ошибка"));
    }
  };
}

/**
 * Clear final sum
 * */
export const CLEAR_FINAL_SUM = "CLEAR_FINAL_SUM";

export function clearFinalSum() {
  return {
    type: CLEAR_FINAL_SUM,
  };
}
