import {
  CLEAR_DIRTY_FIELDS,
  GET_ORDERS_SUCCESS,
  GET_SINGLE_ORDER_ERROR,
  GET_SINGLE_ORDER_LAST_ACTIONS_SUCCESS,
  GET_SINGLE_ORDER_START,
  GET_SINGLE_ORDER_SUCCESS,
  REMOVE_DIRTY_FIELDS,
  REMOVE_FROM_GLOBAL_DTO,
  SET_DIRTY_FIELDS,
  SET_GLOBAL_DTO,
  UPDATE_ORDERS_SUCCESS,
  UPDATE_SINGLE_ORDER,
} from "../actions/orders.actions";

const initialState = {
  list: [],
  singleOrderLoading: false,
  lastActions: {},
  dirtyFields: {},
  isGlobalSaving: false,
  globalDTO: {},
};

export default function ordersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ORDERS_SUCCESS:
    case UPDATE_ORDERS_SUCCESS:
      return { ...state, list: payload };

    case GET_SINGLE_ORDER_START:
      return { ...state, singleOrderLoading: true };
    case GET_SINGLE_ORDER_SUCCESS:
      return { ...state, singleOrderLoading: false, list: [...state.list, payload] };
    case GET_SINGLE_ORDER_ERROR:
      return { ...state, singleOrderLoading: false };

    case GET_SINGLE_ORDER_LAST_ACTIONS_SUCCESS:
      return { ...state, lastActions: { ...state.lastActions, [payload.orderID]: payload.lastActions } };

    case SET_DIRTY_FIELDS:
      return {
        ...state,
        dirtyFields: {
          ...state.dirtyFields,
          [payload.orderID]: state.dirtyFields[payload.orderID]
            ? [...new Set([...state.dirtyFields[payload.orderID], ...payload.fieldNamesArray])]
            : payload.fieldNamesArray,
        },
      };
    case REMOVE_DIRTY_FIELDS:
      const filtered = state.dirtyFields[payload.orderID]?.filter(function (e) {
        return this.indexOf(e) < 0;
      }, payload.fieldNamesArray);
      return { ...state, dirtyFields: { ...state.dirtyFields, [payload.orderID]: filtered } };
    case CLEAR_DIRTY_FIELDS:
      return { ...state, dirtyFields: { ...state.dirtyFields, [payload.orderID]: [] } };

    case SET_GLOBAL_DTO:
      return {
        ...state,
        globalDTO: {
          ...state.globalDTO,
          [payload.orderID]: { ...state.globalDTO[payload.orderID], ...payload.newField },
        },
      };
    case REMOVE_FROM_GLOBAL_DTO:
      return {
        ...state,
        globalDTO: { ...state.globalDTO, [payload.orderID]: payload.globalDTO },
      };
    case UPDATE_SINGLE_ORDER:
      return {
        ...state,
        list: state.list.map((order) => (order.id === payload.order.id ? payload.order : order)),
      };

    default:
      return state;
  }
}
